<template>
  <manage-facility-staff />
</template>

<script>
import ManageFacilityStaff from "@/components/facilities/ManageFacilityStaff";

export default {
  components: {
    ManageFacilityStaff,
  },
};
</script>
