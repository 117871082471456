<template>
  <a-form-item :label="showLabel ? 'Phone Number' : ''">
    <a-input
      v-decorator="[
        'phones',
        {
          initialValue: initialValue,
          rules: [
            { required: true, message: 'Please input your phone number!' },
          ],
        },
      ]"
      style="width: 100%"
      :disabled="disabled"
    >
      <a-select
        slot="addonBefore"
        :filter-option="filterOptions"
        option-filter-prop="dail_code"
        show-search
        v-decorator="[
          'prefixPhone',
          {
            initialValue: initialCountryCode,
          },
        ]"
        style="width: 70px"
        :disabled="disabled"
      >
        <a-select-option
          :value="code.dial_code"
          v-for="(code, index) in countryPhoneCodesFiltered"
          :key="index"
        >
          {{ code.dial_code }}
        </a-select-option>
      </a-select>
    </a-input>
  </a-form-item>
</template>

<script>
import countryPhoneCodes from "@/services/data/contryPhoneCode.json";

export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    initialValue: {
      type: String,
      default: "",
    },
    initialCountryCode: {
      type: String,
      default: "+233",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countryPhoneCodes,
    };
  },
  computed: {
    countryPhoneCodesFiltered() {
      return countryPhoneCodes.filter((code) => code.dial_code);
    },
  },
  methods: {
    filterOptions(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
