<template>
  <div>
    <div class="serviceHeader">
      <div class="font-size-36 font-weight-bold text-dark mb-4">
        Manage Staff
      </div>
      <a-button type="primary" @click="addUser">Add Staff</a-button>
    </div>

    <a-table :columns="columns" :data-source="account.user_ids" key="_id">
      <span slot="email" slot-scope="data">{{ data.email }}</span>
      <span slot="first_name" slot-scope="data">{{ data.first_name }}</span>
      <span slot="last_name" slot-scope="data">{{ data.last_name }}</span>
      <span slot="phone" slot-scope="data">{{ data.phone }}</span>
      <span slot="status" slot-scope="data">{{ data.status }}</span>

      <span slot="action" slot-scope="data, record, index">
        <a @click="editUser(data, record, index)">Edit</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="top"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteEntityUser(index)"
        >
          <template slot="title">
            <p>Are you sure you want to delete?</p>
          </template>
          <a>Delete</a>
        </a-popconfirm>
      </span>
    </a-table>

    <add-facility-staff-form
      v-if="showModal"
      :showModal="showModal"
      @closeModal="closeModal"
    />
  </div>
</template>
<script>
import AddFacilityStaffForm from "@/components/facilities/AddFacilityStaffForm.vue";

import { mapGetters } from "vuex";
import ProfileMixin from "@/mixins/Profile";

export default {
  mixins: [ProfileMixin],
  components: {
    AddFacilityStaffForm,
  },
  data() {
    return {
      columns: [
        {
          title: "Email",
          key: "email",
          scopedSlots: { customRender: "email" },
        },
        {
          title: "First Name",
          key: "first_name",
          scopedSlots: { customRender: "first_name" },
        },
        {
          title: "Last Name",
          key: "last_name",
          scopedSlots: { customRender: "last_name" },
        },
        {
          title: "Phone",
          key: "phone",
          scopedSlots: { customRender: "phone" },
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
      ],
      defaultService: {},
      showModal: false,
    };
  },
  watch: {},
  methods: {
    addUser() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async deleteEntityUser(index) {
      const service = this.data[index];
      await this.deleteProfileService(service._id);
      this.showModal = false;
    },
    editUser(service, record, index) {
      this.defaultService = service;
      this.addUser();
    },
  },
  computed: {
    ...mapGetters("media", ["getMediaUrl"]),
    ...mapGetters("auth", ["currentUserType"]),
    ...mapGetters("tourOperator", ["tourOperatorMedia", "services"]),
    hasUploadedCoverImage() {
      return this.media.cover.path.length > 0;
    },
    hasUploadedLogo() {
      return this.media.logo.path.length > 0;
    },
    media() {
      return this.tourOperatorMedia;
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.serviceHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
