<template>
  <div>
    <a-modal v-model="visible" :title="modalTitle" @ok="addUser">
      <a-spin :spinning="loading">
        <a-form class="mb-4" :form="form">
          <a-form-item label="First Name">
            <a-input
              pattern="[A-Za-z]"
              placeholder="Please enter your first name"
              v-decorator="[
                'first_name',
                {
                  rules: [
                    { required: true, message: 'First Name is required!' },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Last Name">
            <a-input
              pattern="/^[a-z ,.'-]+$/i"
              placeholder="Please enter your last name"
              v-decorator="[
                'last_name',
                {
                  rules: [
                    { required: true, message: 'Last Name is required!' },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item>
            <a-input
              size="large"
              placeholder="Email Address"
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your email address',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <facility-phone-number-form-item :showLabel="false" />

          <a-form-item>
            <a-input
              size="large"
              type="password"
              placeholder="Password"
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: 'Please input your password' },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-form>

        <div>
          <h4>Permissions</h4>
          <div class="permissions">
            <div>
              <div>
                <a-checkbox v-model="permissions.can_add_staff">
                  <span>Can add staff</span>
                </a-checkbox>
              </div>
              <div>
                <a-checkbox v-model="permissions.can_add_service">
                  <span>Can add service</span>
                </a-checkbox>
              </div>
              <div>
                <a-checkbox
                  v-model="permissions.can_update_business_information"
                >
                  <span>Can update business information</span>
                </a-checkbox>
              </div>
              <div>
                <a-checkbox v-model="permissions.can_update_bank_information">
                  <span>Can update bank information</span>
                </a-checkbox>
              </div>
              <div>
                <a-checkbox v-model="permissions.can_update_service">
                  <span>Can update services</span>
                </a-checkbox>
              </div>
            </div>
            <div>
              <div>
                <a-checkbox v-model="permissions.can_remove_service">
                  <span>Can remove service</span>
                </a-checkbox>
              </div>

              <div>
                <a-checkbox v-model="permissions.can_view_transactions">
                  <span>Can view transactions</span>
                </a-checkbox>
              </div>

              <div>
                <a-checkbox v-model="permissions.can_view_reviews">
                  <span>Can view reviews</span>
                </a-checkbox>
              </div>

              <div>
                <a-checkbox v-model="permissions.can_ban_staff">
                  <span>Can ban staff</span>
                </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import ProfileMixin from "@/mixins/Profile";
import { mapActions, mapGetters } from "vuex";

import FacilityPhoneNumberFormItem from "@/components/facilities/FacilityPhoneNumberFormItem";
import { AUTH_GET_ACCOUNT_AND_PROFILE } from "@/store/actions";

export default {
  mixins: [ProfileMixin],
  components: {
    FacilityPhoneNumberFormItem,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    defaultService: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      visible: false,
      form: this.$form.createForm(this, { name: "entityUser" }),
      permissions: {
        can_add_staff: false,
        can_add_service: false,
        can_update_business_information: false,
        can_update_bank_information: false,
        can_update_service: false,
        can_remove_service: false,
        can_view_transactions: false,
        can_view_reviews: false,
        can_ban_staff: false,
      },
    };
  },
  watch: {
    showModal: {
      handler(show) {
        this.visible = show;
      },
      immediate: true,
    },
    visible(show) {
      this.$emit("closeModal", show);
    },
  },
  computed: {
    isEdit() {
      return !!Object.keys(this.defaultService).length;
    },
    modalTitle() {
      return `Add New Staff`;
    },
    ...mapGetters("auth", ["currentUserType"]),
  },
  methods: {
    ...mapActions("auth", {
      refreshData: AUTH_GET_ACCOUNT_AND_PROFILE,
    }),
    coercePhone(number, prefix) {
      return {
        number,
        formatted:
          parseInt(number[0]) === 0
            ? `${prefix}${number.slice(1)}`
            : `${prefix}${number}`,
      };
    },
    addUser() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const formattedPhone = this.coercePhone(
            values.phones,
            values.prefixPhone
          );
          values.phone = formattedPhone.formatted;
          delete values.prefixPhone;
          delete values.phones;
          const user = {
            ...values,
            user_role: "user",
            user_type: this.currentUserType,
            permissions: this.permissions,
          };
          const entity = {
            entity_type: this.currentUserType,
            entity_id: this.account._id,
          };
          const payload = { user, entity };
          try {
            const response = await this.$store.dispatch(
              "auth/AUTH_REGISTER_ENTITY_USER",
              { payload }
            );
            await this.refreshData();
            this.visible = false;
            this.$notification.success({
              message: "Entity user added successfully",
            });
          } catch (error) {
            this.$notification.error({ message: "Error adding entity user" });
          } finally {
            this.loading = false;
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.permissions {
  display: flex;
  flex-direction: row;
}
</style>
